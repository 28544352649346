var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"deposit_layout"},[(_vm.item.role_id !='6191dc644825e6f57ffa231f' && _vm.item.role_id !='6191dce94825e65937fa2340')?_c('span',{staticClass:"mr-1",staticStyle:{"color":"green"}},[_c('a',{attrs:{"disabled":_vm.userp.role_id == '61bf5eb0c73d7eea8634fbf4' ? true : false},on:{"click":_vm.onDialogViewTransaction}},[_c('i',{staticClass:"fas fa-2x fa-file-invoice"})])]):_vm._e(),(_vm.item.role_id !='6191dc644825e6f57ffa231f' && _vm.item.role_id !='6191dce94825e65937fa2340')?_c('span',{staticStyle:{"color":"green"}},[_c('a',{staticClass:"mr-1",attrs:{"disabled":_vm.userp.role_id == '61bf5eb0c73d7eea8634fbf4' ||
          (_vm.userp && !_vm.userp.enable)
            ? true
            : false},on:{"click":_vm.onDialogDeposit}},[_vm._v(" | Cash-in ")])]):_vm._e(),(_vm.item.role_id !='6191dc644825e6f57ffa231f' && _vm.item.role_id !='6191dce94825e65937fa2340')?_c('span',{staticStyle:{"color":"red"}},[_c('a',{staticClass:"mr-1",attrs:{"disabled":_vm.userp.role_id == '61bf5eb0c73d7eea8634fbf4' ||
          (_vm.userp && !_vm.userp.enable)
            ? true
            : false},on:{"click":_vm.onDialogWithdraw}},[_vm._v(" Cash-out ")])]):_vm._e()]),_c('dialog-deposit',{attrs:{"dialog-withdraw":_vm.dialogDeposit,"darkmode":_vm.darkmode,"dialog":_vm.dialogDeposit,"loading":_vm.loading,"user":_vm.item},on:{"submit":_vm.onDeposit,"currentUser":function($event){_vm.updatedDeposit = $event},"onCloseDialog":_vm.closeDialogDeposit}}),_c('dialog-withdraw',{attrs:{"dialog-withdraw":_vm.dialogWithdraw,"darkmode":_vm.darkmode,"dialog":_vm.dialogWithdraw,"loading":_vm.loading,"user":_vm.item},on:{"submit":_vm.onWithdraw,"currentUser":function($event){_vm.updatedWithdaw = $event},"onCloseDialog":_vm.closeDialogWithdraw}}),(_vm.dialogViewTransaction)?_c('dialog-view-transaction',{attrs:{"dialog-withdraw":_vm.dialogViewTransaction,"darkmode":_vm.darkmode,"dialog":_vm.dialogViewTransaction,"selectedUser":_vm.data.selectedUser},on:{"onCloseDialog":_vm.closeDialogViewTransaction}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }